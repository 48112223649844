import {IEnvironment} from '@wallex-core-client/core/interfaces/common.interface';

export const environment: IEnvironment = {
  production: true,
  serverBaseUri: 'https://bcknd.my-bebanker.com',
  wsUrl: 'https://bcknd.my-bebanker.com',
  intercomId: 'hkgmx1f6',
  reduxStoreFreeze: false,
  reduxDevTools: false,
  reCaptchaSiteKey: '6LeIG44oAAAAAInJAft5mJsjrY40_GS4CNIrP7f9',
  isReferralProgramEnabled: true,
  isAuthBanner: false,
  firebaseConfig: {
    apiKey: 'AIzaSyBvApbkuwBvu50dpfH8iGiiRwUQowq03zI',
    authDomain: 'neobanking-wallex.firebaseapp.com',
    projectId: 'neobanking-wallex',
    storageBucket: 'neobanking-wallex.appspot.com',
    messagingSenderId: '913232021732',
    appId: '1:913232021732:web:2c24f1c94b258fa39f44f1',
    measurementId: 'G-DQ0S060MMQ'
  },
  vapidKey: 'BJ_cIVpePs5IijyYGQcpITfGXrvgGf5HqLraG2UOLZh__QVM0773kdb4DOm7uBArRbMyH78zrlh0K1kU8P3zhiA',
  backgroundNotificationChannel: 'background-notification-channel-bebanker',
  backgroundNotificationClickChannel: 'background-notification-click-channel-bebanker',
  sentryDsn: 'https://ba277c6f0517fd45658cc48871e7a3a1@sentry.agilie.com/5'
};
